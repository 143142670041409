import * as React from "react"
import { graphql } from "gatsby"
import PostCard from "components/PostCard/PostCard"
import Pagination from "components/Pagination/Pagination"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { BlogPostsWrapper } from "./templates.style"

const BlogList = (props: any) => {
  const { data } = props
  const Posts = data.allContentfulPost.edges
  const numPages = data.allContentfulPost.group[0].pageInfo.pageCount
  const currentPage = data.allContentfulPost.group[0].pageInfo.currentPage
  const isFirst = currentPage === 1
  const isLast = currentPage === numPages
  const prevPage =
    currentPage - 1 === 1 ? "/page/1" : `/page/${(currentPage - 1).toString()}`
  const nextPage = `/page/${(currentPage + 1).toString()}`
  const PrevLink = !isFirst && prevPage
  const NextLink = !isLast && nextPage

  return (
    <Layout>
      <SEO title={`Page ${currentPage}`} />

      <BlogPostsWrapper>
        {Posts.map(({ node }: any) => {
          return (
            <PostCard
              key={node.slug}
              title={node.title}
              image={
                node.cover == null
                  ? null
                  : node.cover.fluid
              }
              url={node.slug}
              description={node.summary.summary}
              date={node.updatedAt}
              tags={node.tags}
            />
          )
        })}

        <Pagination
          prevLink={PrevLink}
          nextLink={NextLink}
          currentPage={`${currentPage}`}
          totalPage={`${numPages}`}
        />
      </BlogPostsWrapper>
    </Layout>
  )
}

export default BlogList

export const pageQuery = graphql`
  query($skip: Int, $limit: Int) {
    site {
      siteMetadata {
        title
      }
    }
    sitePage {
      path
    }
    
    allContentfulPost(
      sort: { fields: [updatedAt], order: DESC }
      limit: $limit
      skip: $skip
    ) {
      group(field: id) {
        pageInfo {
          pageCount
          currentPage
        }
        totalCount
      }
      edges {
        node {
          title
          slug
          updatedAt(formatString: "DD [<span>] MMM [</span>]")
          tags
          cover {
            fluid(maxWidth: 1170, maxHeight: 690, resizingBehavior: SCALE) {
              ...GatsbyContentfulFluid_withWebp
            }
          }
          summary {
            summary
          }
        }
      }
    }
  }
`
